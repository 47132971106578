import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Buffer } from "buffer"

const createNewsRouteFromCategory = (category, postUri) => {
    const [ministryCode, language] = category.split("-")
    const base64Uri = Buffer.from(postUri).toString("base64")
    const newsPath = `/${ministryCode.toLowerCase()}/news/${base64Uri}`
    return language === "en" ? `/${language.toLowerCase()}${newsPath}` : newsPath
}

const IndexPage = ({ data, location }) => {
    const posts = data.mofaEnPosts.nodes;
    return (
        <Layout location={location} title={"Test"}>
            <h1>MOFA ENGLISH POSTS</h1>
            {posts.map(post => {
                return (
                    <div style={{ margin: "10px" }}>
                        <a href={createNewsRouteFromCategory("mofa-en", post.id)}>
                            {post.title}
                        </a>
                    </div>
                )
            })}
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
  fragment PostFields on WpPost {
    id
    title
    content
    date
    modifiedGmt
    uri
    author {
      node {
        firstName
        lastName
        name
      }
    }
    featuredImage {
      node {
        sourceUrl
      }
    }
    categories {
      nodes {
        name
      }
    }
  }
  query GetWpPosts {
    # Posts in English
    modEnPosts: allWpPost(
      sort: { fields: modifiedGmt, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { name: { regex: "/^mod-en$/i" } } } }
      }
      limit: 5
    ) {
      nodes {
        ...PostFields
      }
    }
    mofaEnPosts: allWpPost(
      sort: { fields: modifiedGmt, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { name: { regex: "/^mofa-en$/i" } } }
        }
      }
    ) {
      nodes {
        ...PostFields
      }
    }
    # Posts in Myanmar
    modMyanmarPosts: allWpPost(
      sort: { fields: modifiedGmt, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { name: { regex: "/^mod-my$/i" } } } }
      }
      limit: 5
    ) {
      nodes {
        ...PostFields
      }
    }
    mofaMyanmarPosts: allWpPost(
      sort: { fields: modifiedGmt, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { name: { regex: "/^mofa-my$/i" } } }
        }
      }
      limit: 5
    ) {
      nodes {
        ...PostFields
      }
    }
  }
`
